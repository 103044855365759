<template>
  <CHeader with-subheader style="background-color: #19C1FF;">
    <!-- <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    /> -->
    <CHeaderBrand class="mx-auto d-lg-none" to="/" style="margin-left: 0px !important;">
      <!-- <CIcon name="logo" height="48" alt="Logo"/> -->
      <img :src="require('@/assets/images/home/logo.png')" style="width: 35%;" />
      <span style="color: #FFFFFF; font-weight: bold;">&nbsp;PARTNERS</span>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <!-- custom -->
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink style="display: flex; align-items: baseline; justify-content: center; color: #FFFFFF; font-weight: bold;"
          to="/c_home"
        >
          <img :src="require('@/assets/images/home/logo.png')" style="width: 35%;" />
            &nbsp;PARTNERS
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <!-- <CHeaderNavItem class="px-3 c-d-legacy-none">
        <button
          @click="() => $store.commit('toggle', 'darkMode')"
          class="c-header-nav-btn"
        >
          <CIcon v-if="$store.state.darkMode" name="cil-sun"/>
          <CIcon v-else name="cil-moon"/>
        </button>
      </CHeaderNavItem>
      <TheHeaderDropdownNotif/>
      <TheHeaderDropdownTasks/>
      <TheHeaderDropdownMssgs/>
      <TheHeaderDropdownAccnt/>
      <CHeaderNavItem class="px-3">
        <button
          class="c-header-nav-btn"
          @click="$store.commit('toggle', 'asideShow')"
        >
          <CIcon size="lg" name="cil-applications-settings" class="mr-2"/>
        </button>
      </CHeaderNavItem> -->
      <!-- custom -->
      <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink style="color: #FFFFFF; font-weight: bold;">
          입출고등록
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink style="color: #FFFFFF; font-weight: bold;"
          to="/c_repairReception"
        >
          수리접수
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink style="color: #FFFFFF; font-weight: bold;"
          to="/c_storeManagement_inventory"
        >
          매장관리
        </CHeaderNavLink>
      </CHeaderNavItem>
      <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink style="color: #FFFFFF; font-weight: bold;">
          커뮤니티
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink style="color: #FFFFFF; font-weight: bold;" v-if="this.userIndex == null"
          to="/pages/c_login"
        >
          로그인
        </CHeaderNavLink>
        <div style="color: #FFFFFF; font-weight: bold;" v-else
          @click="logout()"
        >
          로그아웃
        </div>
      </CHeaderNavItem>
    </CHeaderNav>

    <!-- <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader> -->
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import TheHeaderDropdownNotif from './TheHeaderDropdownNotif'
import TheHeaderDropdownTasks from './TheHeaderDropdownTasks'
import TheHeaderDropdownMssgs from './TheHeaderDropdownMssgs'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownNotif,
    TheHeaderDropdownTasks,
    TheHeaderDropdownMssgs
  },
  data: function () {
    return {
      userIndex: null,
    }
  },
  beforeMount() {
    this.userIndex = localStorage.getItem('userIndex');
    console.log('this.userIndex', this.userIndex);
  },
  methods: {
    homePageRoute() {
      console.log('route');
      this.$router.push({name: 'c_home', params: {}});
    },
    repairReceptionPageRoute() {
      this.$router.push({name: 'c_repairReception', params: {}});
    },
    storeManagementInventoryPageRoute() {
      this.$router.push({name: 'c_storeManagement_inventory', params: {}});
    },
    logout() {
      alert('이용해주셔서 감사합니다.');
      localStorage.clear();
      this.$router.push({name: 'c_home', params: {}});
    }
  },
}
</script>
